import {AfterViewInit, Component, OnInit} from '@angular/core';
import * as moment from 'moment';
import {BaseComponent} from '../../base/base.component';
import {NavigationEnd, Router} from '@angular/router';
import {TranslateService} from '@ngx-translate/core';
import {UserService} from '../../service/user.service';
import {CurrentUserGQL, User} from '../../../generated/graphql';
import {filter} from "rxjs/operators";

declare var $: any;

@Component({
    selector: 'app-main',
    templateUrl: './main.component.html',
    styleUrls: ['./main.component.scss']
})
export class MainComponent extends BaseComponent implements OnInit, AfterViewInit {
    user: User = new User();

    constructor(public router: Router,
                public translate: TranslateService,
                private currentUserGQL: CurrentUserGQL,
                public userService: UserService) {
        super(router, translate);
    }

    ngOnInit(): void {
        moment.locale('pt-br');
        // console.log('aqui main');
        // console.log(this.userService.getStatusAuthenticated());
        if (this.userService.getStatusAuthenticated()) {
            this.getCurrentUser();
        } else {
            localStorage.clear();
            this.userService.isAuthenticated = false;
            this.router.navigate(['/logout']);
        }
    }

    ngAfterViewInit(): void {
        $(document).on('show.bs.modal', '.modal', function() {
            $(this).appendTo('body');
        });
    }

    getCurrentUser() {
        this.currentUserGQL.watch().valueChanges.subscribe(({data}) => {
            this.user = data.currentUser as User;
            this.handlerRouter();
            this.userService.updateUser(this.user);
            this.router.events
                .pipe(filter(event => event instanceof NavigationEnd))
                .subscribe((event: NavigationEnd) => {
                    this.handlerRouter();
                });
        });
    }

    handlerRouter() {
        if (this.user.profile === this.controllerUser) {
            $('#sidebar').addClass('is-hide');
            this.router.navigate(['/avaliation/create/colaborador']);
        }
    }

}
